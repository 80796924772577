@import '../../../../../assets/stylesheets/common/config/colorsMixins';

.alerts-container {
  margin-top: 7rem;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10002;
}

.alert,
.message {
  color: var(--secondary);
  background: transparent;
  clear: both;
  display: table;
  position: relative;
  margin: 0 0 30px;
  padding: 2rem 2rem 2rem 7rem;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  max-width: 936px;

  p {
    margin: 0;
    padding: 0;
  }

  &:before {
    @extend %fa-icon;
    @extend .fas;

    position: absolute;
    left: 0;
    width: 7rem;
    text-align: center;
    font-size: 2.2rem;
    text-transform: none;
  }

  &.alert-danger {
    border-color: var(--danger);

    &:before {
      color: var(--danger);
      content: fa-content($fa-var-exclamation-triangle);
    }
  }

  &.alert-success {
    border-color: var(--info);

    &:before {
      color: var(--info);
      content: fa-content($fa-var-check-circle);
    }
  }

  &.alert-info {
    border-color: var(--primary);
    &:before {
      color: var(--primary);
      content: fa-content($fa-var-info-circle);
    }
  }

  &.alert-inline {
    color: #fff;
    background: var(--primary);
    padding: 1rem;
    font-size: 1.6rem;
    z-index: 10;
  }

  &.is-hidden {
    display: none;
  }

  &.alert-over {
    display: block;
    right: 0;
    margin: 0 1rem 1rem 1rem;
    top: 0;
    position: relative;
    z-index: 10000;
    animation: 3s slide-down;
    min-width: 30rem;
    max-width: 60rem;
    padding: 0;
    overflow: hidden;
    border-radius: 7px;
    box-shadow: 0 4px 4px 0 rgba(50, 50, 93, 0.08);
    background-color: transparentize(#fff, .16);
    backdrop-filter: blur(4px);
    max-height: 100%;
    border: none;

    &:before {
      top: 2.2rem;
    }

    p {
      padding: 2rem 7rem;
      border-radius: 7px;
      border-width: 1px;
      border-style: solid;
    }

    .progress-bar {
      width: 100%;
      height: .75rem;
      position: absolute;
      bottom: 0;
    }

    &.alert-success {
      .progress-bar {
        background: color('info', $alpha: .85);
      }

      p {
        border-color: var(--info);
      }
    }

    &.alert-danger {
      .progress-bar {
        background: color('danger', $alpha: .85);
      }

      p {
        border-color: var(--danger);
      }
    }

    &.alert--close-animation {
      animation: 1s slide-right forwards;
    }
  }
}

.prepaid-terms {
  .message-content {
    .message-link {
      color: #fff;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}

.myAccount {
  .message-content {
    ul {
      display: inline;

      li {
        display: block;
        list-style: none outside none;
      }
    }
  }
}

.request-verification {
  margin-bottom: 20px;
}

.homePage {
  position: relative;

  .message {
    color: #fff;
    margin: 0 auto;
  }

  .app-wrapper {
    background: var(--secondary);
  }
}

.no-data-message {
  font-family: var(--font-family-sans-serif-semibold);
  padding: 5rem 0;
  color: color('gray', $lightness: 15%)
}

@keyframes slide-down {
  0% {
    opacity: 0;
    top: -7rem;
  }

  12% {
    opacity: 1;
    top: 0;
  }

  97% {
    opacity: 1;
    top: 0;
  }
}

@keyframes slide-right {
  0% {
    opacity: 1;
    transform: translateX(0);
    max-height: 50rem;
    filter: blur(0);
  }

  70% {
    opacity: 0;
    transform: translateX(4rem);
    max-height: 50rem;
    filter: blur(.5rem);
  }

  71% {
    opacity: 0;
    transform: translateX(4rem);
    max-height: 50rem;
  }

  100% {
    opacity: 0;
    transform: translateX(4rem);
    max-height: 0;
  }
}

@media only screen and (max-width : 1200px) {
  .homePage {
    .message {
      width: 700rem;
      margin-left: -35rem;
    }
  }
}

@media only screen and (max-width : 768px) {
  .homePage {
    .message {
      width: 40rem;
      margin-left: -20rem;
    }
  }
}

@media only screen and (max-width : 500px) {
  .homePage {
    .message {
      width: 30rem;
      margin-left: -15rem;
    }
  }
}
