@import '../../../../../assets/stylesheets/common/config/colorsMixins';

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="search"],
select.form-control,
textarea.form-control,
input[type="tel"].field-phonenumber.form-control {

  &.form-control {
    &--light {
      background: var(--light-gray);

      &:hover {
        background-color: color('lighter-gray', $lightness: -10%);
        border-color: color('lighter-gray', $lightness: -10%);
      }
    }
  }

  &.form-control-sm {
    height: 4rem;
  }
}

.input-group-text {
  border-radius: .6rem;
}

.radio:not(div) {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.radio input[type="radio"] {
  margin-left: -40px;
}

.radio input {
  position: absolute;
  cursor: pointer;
  appearance: none;
  width: 16px;
  height: 16px;

  &:focus {
    outline: 0;
  }
}

form, .form {
  .form-control[disabled],
  .form-control[readonly],
  fieldset[disabled] .form-control,
  input[type="text"][disabled],
  input[type="password"][disabled],
  input[type="email"][disabled],
  select[disabled],
  textarea[disabled],
  input[readonly],
  select[readonly],
  textarea[readonly] {
    cursor: not-allowed;
    background-color: lighten(#eff2f3, 3%);
    color: #bdc3c6;
  }

  // Previous and Next Button for Wizard
  .controlsWizard-prev {
    &.back {
      margin-right: 10px;

      i {
        font-size: 22px;
        margin-right: 10px;
        vertical-align: middle;
        color: var(--secondary);
      }
    }
  }

  .placeholder {
    color: #aaa;
  }

  .optional {
    font-size: 1.2rem;
    color: var(--gray);
    display: inline-block;
  }

  label {
    color: var(--secondary);
    position: relative;

    &:not(.custom-control-label) {
      font-size: 1.4rem;
    }

    &.required {
      &:after {
        content: '*';
        color: var(--danger);
        position: relative;
        margin-left: 1px;
      }
    }

    &.label-file {
      line-height: 38px;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .form-group {
    &-with-info-icon {
      width: calc(100% - 2.6rem);

      .form-control ~ svg,
      .input-group ~ svg {
        position: absolute;
        right: -2.8rem;
        bottom: 1.5rem;

        &:hover {
          &+ .alert-inline {
            display: block;
            z-index: 20;
            max-width: 100%;
            width: 100%;
          }
        }
      }

      &~.alert-inline,
      &~.form-error {
        width: calc(100% - 2.6rem);
      }
    }

    &:has(.form-control-sm, .custom-checkbox, .multiselect-container, .date-range-picker-btn) {
      .input-group-text {
        font-size: 1.4rem;
        line-height: 1;
      }
    }
  }

  //INPUT GROUP
  .input-group {
    .form-control {
      &.form-error {
        & + .input-group-append {
          .input-group-text {
            border-color: var(--danger);
          }
        }
      }
    }

    .input-group-append {
      .input-group-text {
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }

    &:has(.form-control:focus, .searchBox:focus) {
      .input-group-append {
        .input-group-text {
          border-color: var(--primary);
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
      }
    }

    [disabled] + .input-group-append {
      .input-group-text {
        background-color: lighten(#eff2f3, 3%);
      }
    }
  }

  .form-group,
  .checkbox,
  .radio {
    position: relative;

    .checkbox,
    .radio {
      margin-bottom: initial;
    }

    .alert-inline {
      border: none;
      border-radius: 0;
      display: none;
      margin-top: -4px;
      position: absolute;
      border-bottom-right-radius: 7px;
      border-bottom-left-radius: 7px;

      ul {
        list-style-type: square;
        padding-left: 2rem;
      }
    }
  }

  .form-hint {
    font-size: 14px;
  }

  p {
    &.form-error,
    &.js-formError {
      font-size: 1.2rem;
      align-items: center;
      background: var(--danger);
      border-radius: 0 0 6px 6px;
      color: #fff;
      display: flex;
      font-family: var(--font-family-sans-serif-light);
      left: 0;
      line-height: 11px;
      justify-content: center;
      margin-top: -4px;
      padding: 7px 2px;
      position: absolute;
      text-align: center;
      width: 100%;
      z-index: 10;

      a {
        color: #fff;
        text-decoration: underline;
        font-family: var(--font-family-sans-serif-semibold);
      }

      &--alt {
        background: no-repeat;
        color: var(--danger);
        margin-top: 0;
        font-family: var(--font-family-sans-serif);
      }
    }

    &.is-hidden {
      display: none;
    }
  }

  .form-control {
    &>.alert-inline {
      position: absolute;
      width: 100%;
      display: none;
      top: 80px;
      min-height: 50px;
      border-radius: 0 0 4px 4px;
      border: 0;
      z-index: 10000;
    }

    &-with-question-mark {
      width: calc(100% - 2.6rem);

      &~.alert-inline,
      &~.form-error {
        width: calc(100% - 2.6rem);
      }
    }
  }

  .iban,
  .bic {
    text-transform: uppercase;
  }

  select,
  input,
  textarea,
  .multiselect-container,
  .tox-tinymce,
  input[type="tel"].field-phonenumber.form-control {
    &.form-error {
      border-color: var(--danger);
    }
  }

  .focus-icon {
    position: absolute;
    right: 10px;
    bottom: 15px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    text-align: center;
    font-size: 14px;

    &:hover~.alert-inline {
      display: block;
      z-index: 10;
    }

    i {
      color: var(--primary);
      font-size: 20px;
    }
  }
}

.twoFieldsDate {
  select {
    float: left;
    width: 49.48%;

    &[name*='2'] {
      margin: 0 0.74% 0 0;
    }
  }
}

.js-expirationDate {
  select {
    float: left;
    margin-right: .4%;
    width: 49.6%;
  }
}

.explanatory-text {
  font-size: 1.2rem;
  color: gray;
  font-family: var(--font-family-sans-serif-light);
}

.form-section-title {
  margin: 2rem 0 1.4rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-family: var(--font-family-sans-serif-bold);
  color: color('gray', $lightness: 10%);

  &:after {
    content: '';
    display: inline-block;
    border-top: 1px solid var(--light-gray);
    margin-left: 1rem;
    height: 0;
    flex: 1;
  }
}

.form-group-no-label {
  input,
  .btn {
    margin-top: 23px;
  }
}

//CHECKBOXES/RADIO
.custom-checkbox,
.custom-radio {
  .custom-control-input {
    &:checked {
      ~.custom-control-label {
        color: inherit;

        &:before {
          font: var(--fa-font-light);
          content: "\f00c";
          text-align: center;
          line-height: 21px !important;
        }
      }
    }

    &.form-error {
      ~.custom-control-label {
        color: var(--danger);

        &:before {
          border-color: var(--danger);
        }
      }
    }
  }

  .custom-control-label {
    &:after {
      content: none;
      position: relative;
    }

    &.required {
      &:after {
        content: '*';
        color: var(--danger);
        position: relative;
        left: revert;
        right: auto;
        top: auto;
        margin-left: 1px;
        background-image: none !important;
        display: inline;
      }
    }
  }

  p {
    display: none !important;
  }
}

.sign-up {
  .form-group .alert-inline {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

//AUTOFILL
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-family: var(--font-family-sans-serif-semibold) !important;
  -webkit-text-fill-color: var(--secondary) !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

//PLACEHOLDER
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: color('gray', $lightness: 17%) !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: color('gray', $lightness: 17%) !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: color('gray', $lightness: 17%) !important;
}

@media only screen and (max-width: 768px) {
  form {
    // Previous and Next Button for Wizard
    .controlsWizard {
      .controlsWizard-prev {
        position: relative;
        top: 5px;
        left: auto;
      }
    }
  }
}

.modal-result {
  text-align: center;
}

#popup {
  display: none;
  position: fixed;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  background-color: white;
  z-index: 30001;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
}

#popupdarkbg {
  position: fixed;
  z-index: 30000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, .85);
  display: none;
}

.close {
  position: absolute;
  right: 0px;
  display: inline-block;
  width: 30px;
  height: 30px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    &::before,
    &::after {
      background: #5dabde;
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #000;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &.big {
    transform: scale(3);
  }

  &.black {

    &::before,
    &::after {
      height: 8px;
      margin-top: -4px;
    }
  }
}

.button_to {
  display: inline-block;
}

.grecaptcha-badge {
  z-index: 10;
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 0 !important;
  bottom: 16px !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}
