html {
  font-size: 62.5%;
  height: 100%;

  body {
    padding-bottom: 17rem;
    font-size: 16px;
    position: relative;
    min-height: 100%;

    @include media-breakpoint-up(lg) {
      &.homePage {
        padding-bottom: 80rem;
      }
    }

    .col-centered {
      float: none;
      margin: 0 auto;
    }
  }
}

.app-wrapper {
  .main-content,
  .mainContent {
    max-width: 165rem;
    width: 100%;
    margin: 0 auto;
    padding: 0 2.4rem;
  }
}

@include media-breakpoint-down(lg) {
  html {
    font-size: 56.5%;
  }
}

@include media-breakpoint-down(md) {
  html {
    height: 100%;

    .mainContent,
    .mainContentWrap {
      height: calc(100% - 25px);
      padding: 0;
    }

    .public {
      overflow: initial;
    }
  }

  .homePage .app-wrapper {
    height: auto;
  }
}

.register,
.sign-in {
  padding-top: 17rem;
  font-size: 1.6rem;
}

@include media-breakpoint-down(sm) {
  html {
    body {
      padding-bottom: 0;

      .copyrightWrap,
      .footer {
        position: static !important;
      }
    }
  }
}
