.dropdown {
  &-toggle {
    &:after {
      content: "\f078";
      font: var(--fa-font-light);
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      font-weight: 100;
      margin-left: 1rem;
      border: none;
      vertical-align: baseline;
    }
  }

  &-menu {
    animation: slide-up-dropdown-menu .3s;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1);
  }

  #language,
  #language + [aria-labelledby="language"] a {
    font-family: var(--font-family-sans-serif-semibold);
  }

  [aria-labelledby="my-account-dropdown"]  {
    min-width: 16rem;
    left: auto;
    right: 0;
  }

  #my-account-dropdown {
    @media only screen and (max-width : 480px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 8rem;
    }
  }
}

@keyframes slide-up-dropdown-menu {
  0% {
    top: 120%;
    opacity: 0
  }
  100% {
    top: 100%;
    opacity: 1
  }
}
